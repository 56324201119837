'use client';

import { RxDatabase, RxDocument, createRxDatabase } from 'rxdb';
import { addRxPlugin } from 'rxdb';
import { RxDBCleanupPlugin } from 'rxdb/plugins/cleanup';
import { RxDBcrdtPlugin } from 'rxdb/plugins/crdt';
import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode';
import { RxDBLeaderElectionPlugin } from 'rxdb/plugins/leader-election';
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder';
import { getRxStorageMemory } from 'rxdb/plugins/storage-memory';

import * as Order from './order';

addRxPlugin(RxDBCleanupPlugin);
addRxPlugin(RxDBLeaderElectionPlugin);
addRxPlugin(RxDBQueryBuilderPlugin);
addRxPlugin(RxDBcrdtPlugin);

if (process.env.NODE_ENV !== 'production') {
	addRxPlugin(RxDBDevModePlugin);
}

export type Collections = {
	orders: RxDocument<Order.Collection>;
};

export type Database = RxDatabase<Collections>;

export async function createDatabase(dbPrefix: string) {
	try {
		const db = await createRxDatabase<Collections>({
			name: `${dbPrefix}-oxid-rxdb`, // <- name
			storage: getRxStorageMemory(), // <- RxStorage
			multiInstance: true,

			eventReduce: true,

			/* Optional parameters: */
			// password: 'myPassword', // <- password (optional)
			// multiInstance: true, // <- multiInstance (optional, default: true)
			// eventReduce: true, // <- eventReduce (optional, default: false)
			cleanupPolicy: {
				awaitReplicationsInSync: true,
				waitForLeadership: true,
			}, // <- custom cleanup policy (optional)
		});

		await db.addCollections<Collections>({
			// key = collectionName
			orders: {
				schema: Order.schema,
				methods: Order.methods,
				statics: Order.statics,
				// attachments: {},                      // (optional) ORM-functions for attachments
				// options: {},                          // (optional) Custom parameters that might be used in plugins
				// migrationStrategies: {},              // (optional)
				// autoMigrate: true, // (optional) [default=true]
				// cacheReplacementPolicy: function(){}, // (optional) custom cache replacement policy
				// conflictHandler: function(){}         // (optional) a custom conflict handler can be used
			},
			// you can create multiple collections at once
			// animals: {
			//   // ...
			// }
		});

		// collections.orders.preSave(function (plainData, rxDocument) {
		// 	console.log('🔴 SAVING DOC');
		// 	plainData.uat = new Date().toISOString();
		// }, false);

		db.orders.getOpenOrders;

		return db;
	} catch (err) {
		throw err;
	}
}
