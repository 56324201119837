import isEqual from 'react-fast-compare';
import { RxCollection } from 'rxdb';

import { GetMarketsQuery } from '@/graphql/markets.gql.generated';
import { WarehouseProduct } from '@/providers/WarehouseProvider/warehouse-types';
import { MarketProvider, OrderStatus } from '@/schema.types';

import { PartnerOrderOrdersV4 } from '../../../../../../packages/codegen/__generated__/otto/client';
import { ottoOrderToOxidOrder } from '../utils/otto';

import { methods } from './methods';

import { Doc, RawOrder } from '.';

type Collection = RxCollection<Doc, typeof methods>;

export const statics = {
	getOpenOrders: async function (this: Collection, assignee?: string) {
		const results = await this.find({
			selector: { status: OrderStatus.Open, assignee: assignee ? { $eq: assignee } : { $exists: false } },
		})
			.sort({ cat: 'asc' })
			.exec();

		return results;
	},

	upsertFromMarketplace: async function (
		this: Collection,
		market: GetMarketsQuery['markets'][number],
		rawOrders: RawOrder[],
		products: Map<string, WarehouseProduct<any>>,
	) {
		const upsertedOrders: Doc[] = [];

		switch (market.provider) {
			case MarketProvider.Otto:
				const _rawOrders = rawOrders as PartnerOrderOrdersV4[];

				for (const _order of _rawOrders) {
					const order = ottoOrderToOxidOrder(_order, products, market);

					const existingOrder = await this.findOne({ selector: { id: { $eq: order.id } } }).exec();

					let upsertedOrder: Doc | undefined;

					if (!existingOrder) {
						upsertedOrder = await this.insert({ ...order, iat: new Date().toISOString(), e: 'Order' });
						// HINT: we MUST use toJSON(), otherwise we have proxy object and that will never be equal to a plain object
					} else if (!isEqual(existingOrder.toJSON().raw, order.raw)) {
						upsertedOrder = await existingOrder.updateCRDT({
							ifMatch: {
								$set: order,
							},
						});
					}

					if (upsertedOrder) {
						upsertedOrders.push(upsertedOrder);
					}
				}
		}

		return upsertedOrders;
	},
} as const;
