import { DEFAULT_COOKIE_ATTRIBUTES, OXID_ACCESS_TOKEN_COOKIE_NAME } from '@packages/static/auth';
import { useActor, useSelector } from '@xstate/react';
import cookies from 'js-cookie';
import { useCallback } from 'react';
import { SetNonNullable } from 'type-fest';

import { LogoutReason, UserSessionMachineState, userSessionService } from './user-session-state-machine';

/** ----------------------------------------------------------------------------------------------
 * Accounts
 * _______________________________________________________________________________________________ */

const accountsSelector = (state: UserSessionMachineState) => state.context.accounts;
const currentAccountSelector = (state: UserSessionMachineState) => state.context.currentAccount;

export const useUserSessionAccounts = () => {
	const accounts = useSelector(userSessionService, accountsSelector);
	const currentAccount = useSelector(userSessionService, currentAccountSelector);

	return {
		accounts: accounts!,
		currentAccount: currentAccount!,
	};
};

/** ----------------------------------------------------------------------------------------------
 * User
 * _______________________________________________________________________________________________ */

const userSelector = (state: UserSessionMachineState) => state.context.user;

export const useUserSessionUser = () => {
	const user = useSelector(userSessionService, userSelector);
	return user!;
};

/** ----------------------------------------------------------------------------------------------
 * Tokens
 * _______________________________________________________________________________________________ */

const tokensSelector = (state: UserSessionMachineState) => state.context.tokens;

export const useUserSessionTokens = () => {
	const tokens = useSelector(userSessionService, tokensSelector);
	return tokens as SetNonNullable<typeof tokens>;
};

export const useUserSessionActions = () => {
	const [, send] = useActor(userSessionService);

	return {
		logout: (reason?: LogoutReason) => {
			reason ? send({ type: 'LOGOUT', data: { reason } }) : send('LOGOUT');
		},
	};
};

/**
 * used to install apps like Otto where we'd otherwise need a
 * state in our database to be aware of an ongoing installation
 *
 * @returns
 */
export const useOxidAccessTokenCookieSetter = () => {
	const { oxidAccessToken } = useUserSessionTokens();

	const setCookie = useCallback(
		(expiresInSeconds: number = 120) => {
			cookies.set(OXID_ACCESS_TOKEN_COOKIE_NAME, oxidAccessToken.stringValue, {
				...DEFAULT_COOKIE_ATTRIBUTES,
				expires: new Date(Date.now() + expiresInSeconds * 1000),
			});
		},
		[oxidAccessToken],
	);

	return setCookie;
};
