import { RxJsonSchema } from 'rxdb';
import { getCRDTSchemaPart } from 'rxdb/plugins/crdt';

import { MarketProvider, OrderStatus } from '@/schema.types';

import { contactAddressSchema } from '../document-common';

export enum OrderErrorType {
	UNKNOWN = 'UNKNOWN',
	SHIPMENT = 'SHIPMENT',
	RETURN_SHIPMENT = 'RETURN_SHIPMENT',
	SUBMISSION = 'SUBMISSION',
}

export const literal = {
	title: 'orders schema',
	version: 0,
	description: 'describes a simple order',
	primaryKey: 'id',
	type: 'object',
	/**
	 * we do not have an id as we create that on the server when we save the submitted order
	 */
	properties: {
		id: {
			type: 'string',
			maxLength: 100,
			description: 'we must have this to avoid local conflicts across marketplaces',
		},
		eid: {
			type: 'string',
			description: 'External ID from the market provider',
			maxLength: 100,
		},
		eid2: {
			type: 'string',
			description: 'Secondary external ID from the market provider. For otto, this is the salesOrderId (uuid)',
			maxLength: 100,
		},
		shipmentId: {
			type: 'string',
			description:
				'The shipment ID returned by the market provider. This is used to identify the shipment on the market.',
			maxLength: 100,
		},
		e: {
			type: 'string',
			description: 'Entity of this item. Should be one of `Order`...',
			maxLength: 100,
		},
		cat: {
			type: 'string',
			description: 'ISO 8601 Date-String when this order was created',
			maxLength: 40,
		},
		uat: {
			type: 'string',
			description: 'ISO 8601 Date-String when this order was last updated',
			maxLength: 40,
		},
		iat: {
			type: 'string',
			description: 'ISO 8601 Date-String when this order was imported',
			maxLength: 40,
		},
		sat: {
			type: 'string',
			description: 'ISO 8601 Date-String when this order was shipped',
			maxLength: 40,
		},
		marketProvider: {
			type: 'string',
			enum: Object.values(MarketProvider),
			maxLength: 40,
		},
		marketMerchantId: {
			type: 'string',
			description: 'The merchant ID of the marketplace',
			maxLength: 100,
		},
		marketId: {
			type: 'string',
			description: 'The oxid marketplace ID of the marketplace',
			maxLength: 100,
		},
		raw: {
			type: 'object',
			description: `The raw order as we received it from the marketplace. This is used to be able to re-create the order in case we need to re-import it.`,
		},
		status: {
			type: 'string',
			enum: Object.values(OrderStatus),
			maxLength: 100,
		},
		s: {
			description:
				'FALLBACK: not yet in use! ' +
				"Our internal order status. This won't be updated by marketplace events but only by us! " +
				'Otherwise it could happen that the market overrides a status that we have already changed.',
			type: 'string',
			enum: Object.values(OrderStatus),
			maxLength: 100,
		},
		errors: {
			type: 'array',
			items: {
				type: 'object',
				properties: {
					timestamp: {
						type: 'string',
					},
					type: {
						type: 'string',
						enum: Object.values(OrderErrorType),
					},
					cause: {
						type: 'object',
						properties: {
							code: {
								type: 'string',
							},
							message: {
								type: 'string',
							},
						},
					},
					message: {
						type: 'string',
					},
				},
				required: ['timestamp', 'type', 'message'],
			},
		},
		skus: {
			type: 'array',
			uniqueItems: false,
			items: {
				type: 'string',
				maxLength: 100,
			},
		},
		skusNumeric: {
			type: 'array',
			uniqueItems: false,
			description: `Numeric representation of the skus. Used to group them into batches. Non-parseable skus will set to -1`,
			items: {
				type: 'number',
				maxLength: 100,
			},
		},
		skuHash: {
			type: 'string',
			description: 'Concatenated ascending sorted string of all skus in this order. Used to group them into batches.',
			maxLength: 1000,
		},
		totalWeightGrams: {
			description:
				'🔴 Total weight of the order in grams. This might be undefined in case we have not been able to find positive ' +
				'weights for all included SKUs. In this case, we must issue a warning when we process orders that have no weight!',
			type: 'number',
		},
		senderAddress: contactAddressSchema,
		consigneeAddress: contactAddressSchema,
		shippingDocuments: {
			type: 'object',
			properties: {
				provider: {
					type: 'string',
					enum: ['DHL'],
				},
				trackingNumber: {
					type: 'string',
				},
				returnTrackingNumber: {
					type: 'string',
				},
			},
			required: ['provider', 'trackingNumber'],
		},
		assignee: {
			type: 'string',
			maxLength: 50,
		},
		crdts: getCRDTSchemaPart(),
	},
	crdt: {
		field: 'crdts',
	},
	required: [
		'id',
		'eid',
		'e',
		'cat',
		'iat',
		'marketProvider',
		'marketMerchantId',
		'marketId',
		'raw',
		'status',
		'skus',
		'skusNumeric',
		'skuHash',
		// 'assignee',
		// TODO: this should be set when we request fulfillment labels !
		// 'senderAddress',
		// 'consigneeAddress',
	],
	indexes: [
		'eid',
		'eid2',
		'shipmentId',
		'cat',
		'uat',
		'iat',
		'marketProvider',
		'marketMerchantId',
		'status',
		'assignee',
		'skuHash',
	],
} as const satisfies RxJsonSchema<any>;
