import { ContactAddress } from '@packages/codegen/dhl_parcel_de_shipping_v2_client_browser';
import { ExtractDocumentTypeFromTypedRxJsonSchema } from 'rxdb';

import { contactAddressSchema } from './document-common';

export const mapAddressToDhlAddress: (
	address: ExtractDocumentTypeFromTypedRxJsonSchema<typeof contactAddressSchema>,
) => ContactAddress = (address) => ({
	name1: address.name1,
	name2: address.name2,
	addressStreet: address.street,
	city: address.city,
	country: address.country,
	additionalAddressInformation1: address.additionalInfo,
	email: address.email,
});

export const hashSkus = (skus: string[]) => skus.sort().join('|');

export const getSkuQuantities = (skus: string[]) => {
	const quantities: Record<string, number> = {};
	for (const sku of skus) {
		if (quantities[sku]) {
			quantities[sku]++;
		} else {
			quantities[sku] = 1;
		}
	}
	return quantities;
};
