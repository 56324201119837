export const intoNumeric = (sku: string) => {
	try {
		const parsed = parseInt(sku, 10);
		if (isNaN(parsed)) {
			return -1;
		} else {
			return parsed;
		}
	} catch (e) {
		return -1;
	}
};
