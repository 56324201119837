/* tslint:disable */
/* eslint-disable */
/**
 * Parcel DE Shipping API (Post & Parcel Germany)
 * Note: This is the specification of the DPDHL Group Parcel DE Shipping API for Post & Parcel Germany. This REST web service allows business customers to create shipping labels on demand.
 *
 * The version of the OpenAPI document: 2.1.6
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
export const CustomsDetailsExportTypeEnum = {
    Other: 'OTHER',
    Present: 'PRESENT',
    CommercialSample: 'COMMERCIAL_SAMPLE',
    Document: 'DOCUMENT',
    ReturnOfGoods: 'RETURN_OF_GOODS',
    CommercialGoods: 'COMMERCIAL_GOODS'
};
export const CustomsDetailsShippingConditionsEnum = {
    Ddu: 'DDU',
    Dap: 'DAP',
    Ddp: 'DDP',
    Ddx: 'DDX',
    Dxv: 'DXV'
};
