import { Country } from '@packages/codegen/dhl_parcel_de_shipping_v2_client_browser';
import { TopLevelProperty } from 'rxdb';

export const contactAddressSchema = {
	type: 'object',
	properties: {
		name1: {
			type: 'string',
		},
		name2: {
			type: 'string',
		},
		street: {
			type: 'string',
		},
		house: {
			type: 'string',
		},
		zip: {
			type: 'string',
		},
		city: {
			type: 'string',
		},
		state: {
			type: 'string',
		},
		country: {
			type: 'string',
			enum: Object.values(Country),
		},
		additionalInfo: {
			type: 'string',
		},
		email: {
			type: 'string',
		},
		phone: {
			type: 'string',
		},
	},
	required: ['name1', 'street', 'zip', 'city', 'country'],
} as const satisfies TopLevelProperty;

// export const trackingNumberSchema = {
// 	type: 'object',
// 	properties: {
// 		trackingNumber: {
// 			type: 'string',
// 		},
// 		pdfUrl: {
// 			type: 'string',
// 		},
// 	},
// 	required: ['trackingNumber', 'pdfUrl'],
// } as const satisfies TopLevelProperty;
