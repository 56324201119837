import { Country } from '@packages/codegen/dhl_parcel_de_shipping_v2_client_browser';
import { AddressOrdersV4, AddressShipmentsV1, PartnerOrderOrdersV4 } from '@packages/codegen/otto';
import { groupBy } from 'lodash-es';
import { match } from 'ts-pattern';

import { GetMarketsQuery } from '@/graphql/markets.gql.generated';
import { hashSkus } from '@/lib/db/document-order-utils';
import { OrderDoc } from '@/lib/db/models';
import { WarehouseProduct } from '@/providers/WarehouseProvider/warehouse-types';
import { OrderStatus } from '@/schema.types';

import { intoNumeric } from './shared';

export function processOttoPositionItems(order: PartnerOrderOrdersV4) {
	const positionItemsByFulfillmentStatus = groupBy(order.positionItems, (v) => v.fulfillmentStatus);

	let orderStatus: OrderStatus | null = null;

	const openItems: PartnerOrderOrdersV4['positionItems'] = positionItemsByFulfillmentStatus.PROCESSABLE ?? [];

	if (positionItemsByFulfillmentStatus.ANNOUNCED?.length > 0) {
		// if we have at least ONE item that is ANNOUNCED, the order is not ready to be processed
		orderStatus = OrderStatus.Pending;
	} else if (openItems.length > 0) {
		// if we have NO ANNOUNCED items, but at least one PROCESSABLE item, the order is open
		orderStatus = OrderStatus.Open;
	} else {
		// if we have NO ANNOUNCED items and NO PROCESSABLE items, the order is either to be considered sent, submitted or cancelled
		// none of those matter to us, so we can just pick the first one
		if (positionItemsByFulfillmentStatus.SENT?.length > 0) {
			orderStatus = OrderStatus.Sent;
		} else if (positionItemsByFulfillmentStatus.RETURNED?.length > 0) {
			orderStatus = OrderStatus.Submitted;
		} else if (
			positionItemsByFulfillmentStatus.CANCELLED_BY_MARKETPLACE?.length > 0 ||
			positionItemsByFulfillmentStatus.CANCELLED_BY_PARTNER?.length > 0
		) {
			orderStatus = OrderStatus.Cancelled;
		}
	}

	if (orderStatus === null) {
		throw new Error("Couldn't determine order status");
	}

	return {
		orderStatus,
		openItems,
	};
}

export const ottoOrderToOxidOrder: (
	order: PartnerOrderOrdersV4,
	products: Map<string, WarehouseProduct>,
	market: GetMarketsQuery['markets'][number],
) => Omit<OrderDoc, 'iat' | 'e'> = (order, products, market) => {
	const { orderStatus, openItems } = processOttoPositionItems(order);

	// TODO: re-validate in QuickShip in case we have missing weight info for an sku !
	const totalWeightGrams: number | undefined = order.positionItems.reduce((prev, current) => {
		const productInfo = products.get(current.product.sku);
		const currentWeight = productInfo?.weightGrams ?? 0;
		return (prev !== undefined && currentWeight > 0 ? prev + currentWeight : undefined) as number;
	}, 0);

	// if (!order.deliveryAddress) {
	// 	console.log(order);
	// }

	// eslint-disable-next-line @typescript-eslint/no-unsafe-return
	return {
		// IDs
		id: `${market.provider}-${order.orderNumber}`,
		eid: order.orderNumber,
		eid2: order.salesOrderId,

		// TIMESTAMPS
		cat: new Date(order.orderDate).toISOString(),
		...(order.lastModifiedDate && { uat: new Date(order.lastModifiedDate).toISOString() }),

		// SET BY DATABASE
		// iat: new Date(order.orderDate).toISOString(),
		// e: 'Order',

		marketProvider: market.provider, // market.provider,
		marketMerchantId: market.merchantId,
		marketId: market.id,

		raw: order,

		// assignee: [],

		skuHash: hashSkus(openItems.map((v) => v.product.sku)),
		skus: openItems.map((v) => v.product.sku),
		skusNumeric: openItems.map((v) => intoNumeric(v.product.sku)),

		s: orderStatus,
		status: orderStatus,
		totalWeightGrams,

		consigneeAddress: ottoDeliveryAddressToConsigneeAddress(order.deliveryAddress),
	};
};

const ottoDeliveryAddressToConsigneeAddress: (address: AddressOrdersV4 | undefined) => OrderDoc['consigneeAddress'] = (
	address,
) => {
	if (!address) return undefined;

	const salutation = match(address.salutation)
		.with('MS', () => 'Frau')
		.with('MR', () => 'Herr')
		.otherwise(() => undefined);

	return {
		name1: [salutation, address.title, address.firstName, address.lastName].filter(Boolean).join(' '),
		street: address.street,
		house: address.houseNumber,
		additionalInfo: address.addition,
		email: address.email,
		phone: address.phoneNumber,
		state: address.phoneNumber,
		zip: address.zipCode,
		city: address.city,
		country: address.countryCode as Country,
	};
};

export const oxidSenderAddressToOttoShipmentsAddress: (address: OrderDoc['senderAddress']) => AddressShipmentsV1 = (
	address,
) => {
	if (!address) {
		throw new Error('Sender address is missing');
	}

	return {
		city: address.city,
		countryCode: address.country,
		zipCode: address.zip,
	};
};
