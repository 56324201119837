/* tslint:disable */
/* eslint-disable */
/**
 * Parcel DE Shipping API (Post & Parcel Germany)
 * Note: This is the specification of the DPDHL Group Parcel DE Shipping API for Post & Parcel Germany. This REST web service allows business customers to create shipping labels on demand.
 *
 * The version of the OpenAPI document: 2.1.6
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, BaseAPI } from '../base';
/**
 * ShipmentsAndLabelsApi - axios parameter creator
 * @export
 */
export const ShipmentsAndLabelsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * This request is used to create one or more shipments and return corresponding shipment tracking numbers, labels, and documentation. Up to 30 shipments can be created in a single call.  #### Request  The selected products and corresponding billing numbers, as well as the desired services and package details are required to create a shipment. Each shipment can have a dedicated shipper address. The example request body contains sample values for most services.  #### Response  The request will return shipment tracking numbers and the applicable labels for each shipment. If multiple shipments have been included, an HTTP 207 response (multistatus) is returned and holds detailed status for each shipment. Other standard HTTP response codes (401, 500, 400, 200, 429) are possible, too. Labels can be either provided as part of the response (base64 encoded for PDF, text for ZPL) or via URL link for view and download. Note that the format settings per query parameters apply to the shipping label. It may also apply to other labels included, depending on the configuration of your account. Label paper for return shipments can be specified separately since a different printer may be used here. If requesting labels to be provided as URL for separate download, the URLs can be shared. #### Validation   It is recommended to validate the request first prior to shipment creation by setting the `validate` query parameter to `true`. Especially, during development and test, it is recommended to perform this validation. This functionality supports both  * JSON schema validation (against this API description). During development and test, it is recommended to do this validation. JSON schema is available for local validation  * Dry run against the DHL backend  If this succeeds, actual shipment creation will also succeed.
         * @summary Create one or more shipments and their documents. (This is the primary call of the API.)
         * @param {ShipmentOrderRequest} ShipmentOrderRequest
         * @param {string} [Accept_Language] Control the APIs response language via locale abbreviation. English (en-US) and german (de-DE) are supported. If not specified, the default is english.
         * @param {boolean} [validate] If provided and set to &#x60;true&#x60;, the input document will be:   * validated against JSON schema (/orders/ endpoint) at the API layer. In case of errors, HTTP 400 and details will be returned.   * validated against the DHL backend.   In that case, no state changes are happening, no data is stored, shipments neither deleted nor created, no labels being returned. The call will return a status (200, 400) for each shipment element.
         * @param {boolean} [mustEncode] Legacy name **printOnlyIfCodable**. If set to *true*, labels will only be created if an address is encodable. This is only relevant for German consignee addresses. If set to false or left out, addresses, that are not encodable will be printed even though you receive a warning.
         * @param {CreateOrdersIncludeDocsEnum} [includeDocs] Legacy name **labelResponseType**. Shipping labels and further shipment documents can be:  * __include__: included as base64 encoded data in the response (default)  * __URL__: provided as URL reference.
         * @param {CreateOrdersDocFormatEnum} [docFormat] **Defines** the **printable** document format to be used for label and manifest documents.
         * @param {CreateOrdersPrintFormatEnum} [printFormat] **Defines** the print medium for the shipping label. The different option vary from standard paper sizes DIN A4 and DIN A5 to specific label print formats.  Specific laser print formats using DIN A5 blanks are: * 910-300-600(-oz) (105 x 205mm) * 910-300-300(-oz) (105 x 148mm)  Specific laser print formats **not** using a DIN A5 blank: * 910-300-610 (105 x 208mm) * 100x70mm  Specific thermal print formats: * 910-300-600 (103 x 199mm) * 910-300-400 (103 x 150mm) * 100x70mm  Please use the different formats as follows. If you do not set the parameter the settings of DHL costumer portal account will be used as default.
         * @param {CreateOrdersRetourePrintFormatEnum} [retourePrintFormat] **Defines** the print medium for the return shipping label. This parameter is only usable, if you do not use **combined printing**. The different option vary from standard paper sizes DIN A4 and DIN A5 to specific label print formats.   Specific laser print formats using DIN A5 blanks are: * 910-300-600(-oz) (105 x 205mm) * 910-300-300(-oz) (105 x 148mm)  Specific laser print formats **not** using a DIN A5 blank: * 910-300-610 (105 x 208mm) * 100x70mm  Specific thermal print formats: * 910-300-600 (103 x 199mm) * 910-300-400 (103 x 150mm) * 100x70mm  Please use the different formats as follows. If you do not set the parameter the settings of DHL costumer portal account will be used as default.
         * @param {boolean} [combine] If set, label and return label for one shipment will be printed as single PDF document with possibly multiple pages. Else, those two labels come as separate documents. The option does not affect customs documents and COD labels.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrders: async (ShipmentOrderRequest, Accept_Language, validate, mustEncode, includeDocs, docFormat, printFormat, retourePrintFormat, combine, options = {}) => {
            // verify required parameter 'ShipmentOrderRequest' is not null or undefined
            assertParamExists('createOrders', 'ShipmentOrderRequest', ShipmentOrderRequest);
            const localVarPath = `/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "dhl-api-key", configuration);
            // authentication BasicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);
            if (validate !== undefined) {
                localVarQueryParameter['validate'] = validate;
            }
            if (mustEncode !== undefined) {
                localVarQueryParameter['mustEncode'] = mustEncode;
            }
            if (includeDocs !== undefined) {
                localVarQueryParameter['includeDocs'] = includeDocs;
            }
            if (docFormat !== undefined) {
                localVarQueryParameter['docFormat'] = docFormat;
            }
            if (printFormat !== undefined) {
                localVarQueryParameter['printFormat'] = printFormat;
            }
            if (retourePrintFormat !== undefined) {
                localVarQueryParameter['retourePrintFormat'] = retourePrintFormat;
            }
            if (combine !== undefined) {
                localVarQueryParameter['combine'] = combine;
            }
            if (Accept_Language != null) {
                localVarHeaderParameter['Accept-Language'] = String(Accept_Language);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(ShipmentOrderRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Public download URL for shipment labels and documents. The URL is provided in the response of the POST /orders or GET /orders resources. The document is identified via the token query parameter. There is no additional authorization, the resource URL can be shared. Please protect the URL as needed. The call returns a PDF label.
         * @summary Download PDF document
         * @param {string} token Identifies PDF document and requested print settings for download.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabel: async (token, options = {}) => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('getLabel', 'token', token);
            const localVarPath = `/labels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns documents for existing shipment(s). The call accepts multiple shipment numbers and will provide sets of documents for those. The **format (PDF,ZPL)** and **method of delivery (URL, encoded, data)** can be selected for **all** shipments and labels in that call. You cannot chose one format and delivery method for one label and different for another label within the same call. You can also specify if you want regular labels, return labels, cod labels, or customsDoc. Any combination is possible.  The call returns for each shipment number the status indicator and the selected labels and documents. If a label type (for example a cod label) does not exist for a shipment, it will not be returned. This is not an error. If you were sending multiple shipments, you will get an HTTP 207 response (multistatus) with detailed status for each shipment. Other standard HTTP response codes (200, 400, 401, 429, 500) are possible as well. Labels can be either provided as part of the response (base64 encoded for PDF, text for ZPL) or via URL link for view and download (PDF). Note that the format settings per query parameters apply to the shipping label. Retoure label paper type can be specified separately since a different printer may be used here. If requesting labels to be returned as URL for separate download, the URLs provided can be shared.
         * @summary Retrieve shipment documents - labels and customs documents
         * @param {Array<string>} shipment This parameter identifies shipments. The parameter can be used multiple times in one request to get the labels and/or documents for up to 30 shipments maximum. Only documents and label for shipments that are not yet closed can be retrieved.
         * @param {string} [Accept_Language] Control the APIs response language via locale abbreviation. English (en-US) and german (de-DE) are supported. If not specified, the default is english.
         * @param {GetOrderDocFormatEnum} [docFormat] **Defines** the **printable** document format to be used for label and manifest documents.
         * @param {GetOrderPrintFormatEnum} [printFormat] **Defines** the print medium for the shipping label. The different option vary from standard papersizes DIN A4 and DIN A5 to specific label print formats.   Specific laser print formats using DIN A5 blanks are:  * 910-300-600(-oz) (105 x 205mm) * 910-300-300(-oz) (105 x 148mm)  Specific laser print formats **not** using a DIN A5 blank:  * 910-300-610 (105 x 208mm) * 100x70mm  Specific thermal print formats:  * 910-300-600 (103 x 199mm) * 910-300-400 (103 x 150mm) * 100x70mm  Please use the different formats as follows. If you do not set the parameter the settings of DHL costumer portal account will be used as default.
         * @param {GetOrderRetourePrintFormatEnum} [retourePrintFormat] **Defines** the print medium for the return shipping label. This parameter is only usable, if you do not use **combined printing**. The different option vary from standard papersizes DIN A4 and DIN A5 to specific label print formats.   Specific laser print formats using DIN A5 blanks are:  * 910-300-600(-oz) (105 x 205mm) * 910-300-300(-oz) (105 x 148mm)  Specific laser print formats **not** using a DIN A5 blank:  * 910-300-610 (105 x 208mm) * 100x70mm  Specific thermal print formats:  * 910-300-600 (103 x 199mm) * 910-300-400 (103 x 150mm) * 100x70mm  Please use the different formats as follows. If you do not set the parameter the settings of DHL costumer portal account will be used as default.
         * @param {GetOrderIncludeDocsEnum} [includeDocs] Legacy name **labelResponseType**. Shipping labels and further shipment documents can be:  * __include__: included as base64 encoded data in the response (default)  * __URL__: provided as URL reference.  Default is include the base64 encoded labels.
         * @param {boolean} [combine] If set, label and return label for one shipment will be printed as single PDF document with possibly multiple pages. Else, those two labels come as separate documents. The option does not affect customs documents and COD labels.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrder: async (shipment, Accept_Language, docFormat, printFormat, retourePrintFormat, includeDocs, combine, options = {}) => {
            // verify required parameter 'shipment' is not null or undefined
            assertParamExists('getOrder', 'shipment', shipment);
            const localVarPath = `/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "dhl-api-key", configuration);
            // authentication BasicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);
            if (shipment) {
                localVarQueryParameter['shipment'] = shipment;
            }
            if (docFormat !== undefined) {
                localVarQueryParameter['docFormat'] = docFormat;
            }
            if (printFormat !== undefined) {
                localVarQueryParameter['printFormat'] = printFormat;
            }
            if (retourePrintFormat !== undefined) {
                localVarQueryParameter['retourePrintFormat'] = retourePrintFormat;
            }
            if (includeDocs !== undefined) {
                localVarQueryParameter['includeDocs'] = includeDocs;
            }
            if (combine !== undefined) {
                localVarQueryParameter['combine'] = combine;
            }
            if (Accept_Language != null) {
                localVarHeaderParameter['Accept-Language'] = String(Accept_Language);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete one or more shipments created earlier. Deletion of shipments is only possible prior to them being manifested (closed out, \'Tagesabschluss\'). The call will return HTTP 200 (single shipment) or 207 on success, with individual status elements for each shipment. Individual status elements are HTTP 200, 400. 400 will be returned when shipment does not exist (or was already deleted).
         * @summary Delete one or more shipments
         * @param {string} profile Defines the user group profile. A user group is permitted to specific billing numbers. Shipments are only canceled if they belong to a billing number that the user group profile is entitled to use. This attribute is mandatory. Please use the standard user group profile \&#39;STANDARD_GRUPPENPROFIL\&#39; if no dedicated user group profile is available.
         * @param {string} shipment Shipment number that shall be canceled. If multiple shipments shall be canceled, the parameter must be added multiple times. Up to 30 shipments can be canceled at once.
         * @param {string} [Accept_Language] Control the APIs response language via locale abbreviation. English (en-US) and german (de-DE) are supported. If not specified, the default is english.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersAccountDelete: async (profile, shipment, Accept_Language, options = {}) => {
            // verify required parameter 'profile' is not null or undefined
            assertParamExists('ordersAccountDelete', 'profile', profile);
            // verify required parameter 'shipment' is not null or undefined
            assertParamExists('ordersAccountDelete', 'shipment', shipment);
            const localVarPath = `/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "dhl-api-key", configuration);
            // authentication BasicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);
            if (profile !== undefined) {
                localVarQueryParameter['profile'] = profile;
            }
            if (shipment !== undefined) {
                localVarQueryParameter['shipment'] = shipment;
            }
            if (Accept_Language != null) {
                localVarHeaderParameter['Accept-Language'] = String(Accept_Language);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * ShipmentsAndLabelsApi - functional programming interface
 * @export
 */
export const ShipmentsAndLabelsApiFp = function (configuration) {
    const localVarAxiosParamCreator = ShipmentsAndLabelsApiAxiosParamCreator(configuration);
    return {
        /**
         * This request is used to create one or more shipments and return corresponding shipment tracking numbers, labels, and documentation. Up to 30 shipments can be created in a single call.  #### Request  The selected products and corresponding billing numbers, as well as the desired services and package details are required to create a shipment. Each shipment can have a dedicated shipper address. The example request body contains sample values for most services.  #### Response  The request will return shipment tracking numbers and the applicable labels for each shipment. If multiple shipments have been included, an HTTP 207 response (multistatus) is returned and holds detailed status for each shipment. Other standard HTTP response codes (401, 500, 400, 200, 429) are possible, too. Labels can be either provided as part of the response (base64 encoded for PDF, text for ZPL) or via URL link for view and download. Note that the format settings per query parameters apply to the shipping label. It may also apply to other labels included, depending on the configuration of your account. Label paper for return shipments can be specified separately since a different printer may be used here. If requesting labels to be provided as URL for separate download, the URLs can be shared. #### Validation   It is recommended to validate the request first prior to shipment creation by setting the `validate` query parameter to `true`. Especially, during development and test, it is recommended to perform this validation. This functionality supports both  * JSON schema validation (against this API description). During development and test, it is recommended to do this validation. JSON schema is available for local validation  * Dry run against the DHL backend  If this succeeds, actual shipment creation will also succeed.
         * @summary Create one or more shipments and their documents. (This is the primary call of the API.)
         * @param {ShipmentOrderRequest} ShipmentOrderRequest
         * @param {string} [Accept_Language] Control the APIs response language via locale abbreviation. English (en-US) and german (de-DE) are supported. If not specified, the default is english.
         * @param {boolean} [validate] If provided and set to &#x60;true&#x60;, the input document will be:   * validated against JSON schema (/orders/ endpoint) at the API layer. In case of errors, HTTP 400 and details will be returned.   * validated against the DHL backend.   In that case, no state changes are happening, no data is stored, shipments neither deleted nor created, no labels being returned. The call will return a status (200, 400) for each shipment element.
         * @param {boolean} [mustEncode] Legacy name **printOnlyIfCodable**. If set to *true*, labels will only be created if an address is encodable. This is only relevant for German consignee addresses. If set to false or left out, addresses, that are not encodable will be printed even though you receive a warning.
         * @param {CreateOrdersIncludeDocsEnum} [includeDocs] Legacy name **labelResponseType**. Shipping labels and further shipment documents can be:  * __include__: included as base64 encoded data in the response (default)  * __URL__: provided as URL reference.
         * @param {CreateOrdersDocFormatEnum} [docFormat] **Defines** the **printable** document format to be used for label and manifest documents.
         * @param {CreateOrdersPrintFormatEnum} [printFormat] **Defines** the print medium for the shipping label. The different option vary from standard paper sizes DIN A4 and DIN A5 to specific label print formats.  Specific laser print formats using DIN A5 blanks are: * 910-300-600(-oz) (105 x 205mm) * 910-300-300(-oz) (105 x 148mm)  Specific laser print formats **not** using a DIN A5 blank: * 910-300-610 (105 x 208mm) * 100x70mm  Specific thermal print formats: * 910-300-600 (103 x 199mm) * 910-300-400 (103 x 150mm) * 100x70mm  Please use the different formats as follows. If you do not set the parameter the settings of DHL costumer portal account will be used as default.
         * @param {CreateOrdersRetourePrintFormatEnum} [retourePrintFormat] **Defines** the print medium for the return shipping label. This parameter is only usable, if you do not use **combined printing**. The different option vary from standard paper sizes DIN A4 and DIN A5 to specific label print formats.   Specific laser print formats using DIN A5 blanks are: * 910-300-600(-oz) (105 x 205mm) * 910-300-300(-oz) (105 x 148mm)  Specific laser print formats **not** using a DIN A5 blank: * 910-300-610 (105 x 208mm) * 100x70mm  Specific thermal print formats: * 910-300-600 (103 x 199mm) * 910-300-400 (103 x 150mm) * 100x70mm  Please use the different formats as follows. If you do not set the parameter the settings of DHL costumer portal account will be used as default.
         * @param {boolean} [combine] If set, label and return label for one shipment will be printed as single PDF document with possibly multiple pages. Else, those two labels come as separate documents. The option does not affect customs documents and COD labels.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrders(ShipmentOrderRequest, Accept_Language, validate, mustEncode, includeDocs, docFormat, printFormat, retourePrintFormat, combine, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrders(ShipmentOrderRequest, Accept_Language, validate, mustEncode, includeDocs, docFormat, printFormat, retourePrintFormat, combine, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Public download URL for shipment labels and documents. The URL is provided in the response of the POST /orders or GET /orders resources. The document is identified via the token query parameter. There is no additional authorization, the resource URL can be shared. Please protect the URL as needed. The call returns a PDF label.
         * @summary Download PDF document
         * @param {string} token Identifies PDF document and requested print settings for download.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLabel(token, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLabel(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns documents for existing shipment(s). The call accepts multiple shipment numbers and will provide sets of documents for those. The **format (PDF,ZPL)** and **method of delivery (URL, encoded, data)** can be selected for **all** shipments and labels in that call. You cannot chose one format and delivery method for one label and different for another label within the same call. You can also specify if you want regular labels, return labels, cod labels, or customsDoc. Any combination is possible.  The call returns for each shipment number the status indicator and the selected labels and documents. If a label type (for example a cod label) does not exist for a shipment, it will not be returned. This is not an error. If you were sending multiple shipments, you will get an HTTP 207 response (multistatus) with detailed status for each shipment. Other standard HTTP response codes (200, 400, 401, 429, 500) are possible as well. Labels can be either provided as part of the response (base64 encoded for PDF, text for ZPL) or via URL link for view and download (PDF). Note that the format settings per query parameters apply to the shipping label. Retoure label paper type can be specified separately since a different printer may be used here. If requesting labels to be returned as URL for separate download, the URLs provided can be shared.
         * @summary Retrieve shipment documents - labels and customs documents
         * @param {Array<string>} shipment This parameter identifies shipments. The parameter can be used multiple times in one request to get the labels and/or documents for up to 30 shipments maximum. Only documents and label for shipments that are not yet closed can be retrieved.
         * @param {string} [Accept_Language] Control the APIs response language via locale abbreviation. English (en-US) and german (de-DE) are supported. If not specified, the default is english.
         * @param {GetOrderDocFormatEnum} [docFormat] **Defines** the **printable** document format to be used for label and manifest documents.
         * @param {GetOrderPrintFormatEnum} [printFormat] **Defines** the print medium for the shipping label. The different option vary from standard papersizes DIN A4 and DIN A5 to specific label print formats.   Specific laser print formats using DIN A5 blanks are:  * 910-300-600(-oz) (105 x 205mm) * 910-300-300(-oz) (105 x 148mm)  Specific laser print formats **not** using a DIN A5 blank:  * 910-300-610 (105 x 208mm) * 100x70mm  Specific thermal print formats:  * 910-300-600 (103 x 199mm) * 910-300-400 (103 x 150mm) * 100x70mm  Please use the different formats as follows. If you do not set the parameter the settings of DHL costumer portal account will be used as default.
         * @param {GetOrderRetourePrintFormatEnum} [retourePrintFormat] **Defines** the print medium for the return shipping label. This parameter is only usable, if you do not use **combined printing**. The different option vary from standard papersizes DIN A4 and DIN A5 to specific label print formats.   Specific laser print formats using DIN A5 blanks are:  * 910-300-600(-oz) (105 x 205mm) * 910-300-300(-oz) (105 x 148mm)  Specific laser print formats **not** using a DIN A5 blank:  * 910-300-610 (105 x 208mm) * 100x70mm  Specific thermal print formats:  * 910-300-600 (103 x 199mm) * 910-300-400 (103 x 150mm) * 100x70mm  Please use the different formats as follows. If you do not set the parameter the settings of DHL costumer portal account will be used as default.
         * @param {GetOrderIncludeDocsEnum} [includeDocs] Legacy name **labelResponseType**. Shipping labels and further shipment documents can be:  * __include__: included as base64 encoded data in the response (default)  * __URL__: provided as URL reference.  Default is include the base64 encoded labels.
         * @param {boolean} [combine] If set, label and return label for one shipment will be printed as single PDF document with possibly multiple pages. Else, those two labels come as separate documents. The option does not affect customs documents and COD labels.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrder(shipment, Accept_Language, docFormat, printFormat, retourePrintFormat, includeDocs, combine, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrder(shipment, Accept_Language, docFormat, printFormat, retourePrintFormat, includeDocs, combine, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete one or more shipments created earlier. Deletion of shipments is only possible prior to them being manifested (closed out, \'Tagesabschluss\'). The call will return HTTP 200 (single shipment) or 207 on success, with individual status elements for each shipment. Individual status elements are HTTP 200, 400. 400 will be returned when shipment does not exist (or was already deleted).
         * @summary Delete one or more shipments
         * @param {string} profile Defines the user group profile. A user group is permitted to specific billing numbers. Shipments are only canceled if they belong to a billing number that the user group profile is entitled to use. This attribute is mandatory. Please use the standard user group profile \&#39;STANDARD_GRUPPENPROFIL\&#39; if no dedicated user group profile is available.
         * @param {string} shipment Shipment number that shall be canceled. If multiple shipments shall be canceled, the parameter must be added multiple times. Up to 30 shipments can be canceled at once.
         * @param {string} [Accept_Language] Control the APIs response language via locale abbreviation. English (en-US) and german (de-DE) are supported. If not specified, the default is english.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersAccountDelete(profile, shipment, Accept_Language, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersAccountDelete(profile, shipment, Accept_Language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};
/**
 * ShipmentsAndLabelsApi - factory interface
 * @export
 */
export const ShipmentsAndLabelsApiFactory = function (configuration, basePath, axios) {
    const localVarFp = ShipmentsAndLabelsApiFp(configuration);
    return {
        /**
         * This request is used to create one or more shipments and return corresponding shipment tracking numbers, labels, and documentation. Up to 30 shipments can be created in a single call.  #### Request  The selected products and corresponding billing numbers, as well as the desired services and package details are required to create a shipment. Each shipment can have a dedicated shipper address. The example request body contains sample values for most services.  #### Response  The request will return shipment tracking numbers and the applicable labels for each shipment. If multiple shipments have been included, an HTTP 207 response (multistatus) is returned and holds detailed status for each shipment. Other standard HTTP response codes (401, 500, 400, 200, 429) are possible, too. Labels can be either provided as part of the response (base64 encoded for PDF, text for ZPL) or via URL link for view and download. Note that the format settings per query parameters apply to the shipping label. It may also apply to other labels included, depending on the configuration of your account. Label paper for return shipments can be specified separately since a different printer may be used here. If requesting labels to be provided as URL for separate download, the URLs can be shared. #### Validation   It is recommended to validate the request first prior to shipment creation by setting the `validate` query parameter to `true`. Especially, during development and test, it is recommended to perform this validation. This functionality supports both  * JSON schema validation (against this API description). During development and test, it is recommended to do this validation. JSON schema is available for local validation  * Dry run against the DHL backend  If this succeeds, actual shipment creation will also succeed.
         * @summary Create one or more shipments and their documents. (This is the primary call of the API.)
         * @param {ShipmentsAndLabelsApiCreateOrdersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrders(requestParameters, options) {
            return localVarFp.createOrders(requestParameters.ShipmentOrderRequest, requestParameters.Accept_Language, requestParameters.validate, requestParameters.mustEncode, requestParameters.includeDocs, requestParameters.docFormat, requestParameters.printFormat, requestParameters.retourePrintFormat, requestParameters.combine, options).then((request) => request(axios, basePath));
        },
        /**
         * Public download URL for shipment labels and documents. The URL is provided in the response of the POST /orders or GET /orders resources. The document is identified via the token query parameter. There is no additional authorization, the resource URL can be shared. Please protect the URL as needed. The call returns a PDF label.
         * @summary Download PDF document
         * @param {ShipmentsAndLabelsApiGetLabelRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabel(requestParameters, options) {
            return localVarFp.getLabel(requestParameters.token, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns documents for existing shipment(s). The call accepts multiple shipment numbers and will provide sets of documents for those. The **format (PDF,ZPL)** and **method of delivery (URL, encoded, data)** can be selected for **all** shipments and labels in that call. You cannot chose one format and delivery method for one label and different for another label within the same call. You can also specify if you want regular labels, return labels, cod labels, or customsDoc. Any combination is possible.  The call returns for each shipment number the status indicator and the selected labels and documents. If a label type (for example a cod label) does not exist for a shipment, it will not be returned. This is not an error. If you were sending multiple shipments, you will get an HTTP 207 response (multistatus) with detailed status for each shipment. Other standard HTTP response codes (200, 400, 401, 429, 500) are possible as well. Labels can be either provided as part of the response (base64 encoded for PDF, text for ZPL) or via URL link for view and download (PDF). Note that the format settings per query parameters apply to the shipping label. Retoure label paper type can be specified separately since a different printer may be used here. If requesting labels to be returned as URL for separate download, the URLs provided can be shared.
         * @summary Retrieve shipment documents - labels and customs documents
         * @param {ShipmentsAndLabelsApiGetOrderRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrder(requestParameters, options) {
            return localVarFp.getOrder(requestParameters.shipment, requestParameters.Accept_Language, requestParameters.docFormat, requestParameters.printFormat, requestParameters.retourePrintFormat, requestParameters.includeDocs, requestParameters.combine, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete one or more shipments created earlier. Deletion of shipments is only possible prior to them being manifested (closed out, \'Tagesabschluss\'). The call will return HTTP 200 (single shipment) or 207 on success, with individual status elements for each shipment. Individual status elements are HTTP 200, 400. 400 will be returned when shipment does not exist (or was already deleted).
         * @summary Delete one or more shipments
         * @param {ShipmentsAndLabelsApiOrdersAccountDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersAccountDelete(requestParameters, options) {
            return localVarFp.ordersAccountDelete(requestParameters.profile, requestParameters.shipment, requestParameters.Accept_Language, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * ShipmentsAndLabelsApi - object-oriented interface
 * @export
 * @class ShipmentsAndLabelsApi
 * @extends {BaseAPI}
 */
export class ShipmentsAndLabelsApi extends BaseAPI {
    /**
     * This request is used to create one or more shipments and return corresponding shipment tracking numbers, labels, and documentation. Up to 30 shipments can be created in a single call.  #### Request  The selected products and corresponding billing numbers, as well as the desired services and package details are required to create a shipment. Each shipment can have a dedicated shipper address. The example request body contains sample values for most services.  #### Response  The request will return shipment tracking numbers and the applicable labels for each shipment. If multiple shipments have been included, an HTTP 207 response (multistatus) is returned and holds detailed status for each shipment. Other standard HTTP response codes (401, 500, 400, 200, 429) are possible, too. Labels can be either provided as part of the response (base64 encoded for PDF, text for ZPL) or via URL link for view and download. Note that the format settings per query parameters apply to the shipping label. It may also apply to other labels included, depending on the configuration of your account. Label paper for return shipments can be specified separately since a different printer may be used here. If requesting labels to be provided as URL for separate download, the URLs can be shared. #### Validation   It is recommended to validate the request first prior to shipment creation by setting the `validate` query parameter to `true`. Especially, during development and test, it is recommended to perform this validation. This functionality supports both  * JSON schema validation (against this API description). During development and test, it is recommended to do this validation. JSON schema is available for local validation  * Dry run against the DHL backend  If this succeeds, actual shipment creation will also succeed.
     * @summary Create one or more shipments and their documents. (This is the primary call of the API.)
     * @param {ShipmentsAndLabelsApiCreateOrdersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShipmentsAndLabelsApi
     */
    createOrders(requestParameters, options) {
        return ShipmentsAndLabelsApiFp(this.configuration).createOrders(requestParameters.ShipmentOrderRequest, requestParameters.Accept_Language, requestParameters.validate, requestParameters.mustEncode, requestParameters.includeDocs, requestParameters.docFormat, requestParameters.printFormat, requestParameters.retourePrintFormat, requestParameters.combine, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Public download URL for shipment labels and documents. The URL is provided in the response of the POST /orders or GET /orders resources. The document is identified via the token query parameter. There is no additional authorization, the resource URL can be shared. Please protect the URL as needed. The call returns a PDF label.
     * @summary Download PDF document
     * @param {ShipmentsAndLabelsApiGetLabelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShipmentsAndLabelsApi
     */
    getLabel(requestParameters, options) {
        return ShipmentsAndLabelsApiFp(this.configuration).getLabel(requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Returns documents for existing shipment(s). The call accepts multiple shipment numbers and will provide sets of documents for those. The **format (PDF,ZPL)** and **method of delivery (URL, encoded, data)** can be selected for **all** shipments and labels in that call. You cannot chose one format and delivery method for one label and different for another label within the same call. You can also specify if you want regular labels, return labels, cod labels, or customsDoc. Any combination is possible.  The call returns for each shipment number the status indicator and the selected labels and documents. If a label type (for example a cod label) does not exist for a shipment, it will not be returned. This is not an error. If you were sending multiple shipments, you will get an HTTP 207 response (multistatus) with detailed status for each shipment. Other standard HTTP response codes (200, 400, 401, 429, 500) are possible as well. Labels can be either provided as part of the response (base64 encoded for PDF, text for ZPL) or via URL link for view and download (PDF). Note that the format settings per query parameters apply to the shipping label. Retoure label paper type can be specified separately since a different printer may be used here. If requesting labels to be returned as URL for separate download, the URLs provided can be shared.
     * @summary Retrieve shipment documents - labels and customs documents
     * @param {ShipmentsAndLabelsApiGetOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShipmentsAndLabelsApi
     */
    getOrder(requestParameters, options) {
        return ShipmentsAndLabelsApiFp(this.configuration).getOrder(requestParameters.shipment, requestParameters.Accept_Language, requestParameters.docFormat, requestParameters.printFormat, requestParameters.retourePrintFormat, requestParameters.includeDocs, requestParameters.combine, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Delete one or more shipments created earlier. Deletion of shipments is only possible prior to them being manifested (closed out, \'Tagesabschluss\'). The call will return HTTP 200 (single shipment) or 207 on success, with individual status elements for each shipment. Individual status elements are HTTP 200, 400. 400 will be returned when shipment does not exist (or was already deleted).
     * @summary Delete one or more shipments
     * @param {ShipmentsAndLabelsApiOrdersAccountDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShipmentsAndLabelsApi
     */
    ordersAccountDelete(requestParameters, options) {
        return ShipmentsAndLabelsApiFp(this.configuration).ordersAccountDelete(requestParameters.profile, requestParameters.shipment, requestParameters.Accept_Language, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * @export
 */
export const CreateOrdersIncludeDocsEnum = {
    Include: 'include',
    Url: 'URL'
};
/**
 * @export
 */
export const CreateOrdersDocFormatEnum = {
    Zpl2: 'ZPL2',
    Pdf: 'PDF'
};
/**
 * @export
 */
export const CreateOrdersPrintFormatEnum = {
    A4: 'A4',
    _910300600: '910-300-600',
    _910300610: '910-300-610',
    _910300700: '910-300-700',
    _910300700Oz: '910-300-700-oz',
    _910300710: '910-300-710',
    _910300300: '910-300-300',
    _910300300Oz: '910-300-300-oz',
    _910300400: '910-300-400',
    _910300410: '910-300-410',
    _100x70mm: '100x70mm'
};
/**
 * @export
 */
export const CreateOrdersRetourePrintFormatEnum = {
    A4: 'A4',
    _910300600: '910-300-600',
    _910300610: '910-300-610',
    _910300700: '910-300-700',
    _910300700Oz: '910-300-700-oz',
    _910300710: '910-300-710',
    _910300300: '910-300-300',
    _910300300Oz: '910-300-300-oz',
    _910300400: '910-300-400',
    _910300410: '910-300-410',
    _100x70mm: '100x70mm'
};
/**
 * @export
 */
export const GetOrderDocFormatEnum = {
    Zpl2: 'ZPL2',
    Pdf: 'PDF'
};
/**
 * @export
 */
export const GetOrderPrintFormatEnum = {
    A4: 'A4',
    _910300600: '910-300-600',
    _910300610: '910-300-610',
    _910300700: '910-300-700',
    _910300700Oz: '910-300-700-oz',
    _910300710: '910-300-710',
    _910300300: '910-300-300',
    _910300300Oz: '910-300-300-oz',
    _910300400: '910-300-400',
    _910300410: '910-300-410',
    _100x70mm: '100x70mm'
};
/**
 * @export
 */
export const GetOrderRetourePrintFormatEnum = {
    A4: 'A4',
    _910300600: '910-300-600',
    _910300610: '910-300-610',
    _910300700: '910-300-700',
    _910300700Oz: '910-300-700-oz',
    _910300710: '910-300-710',
    _910300300: '910-300-300',
    _910300300Oz: '910-300-300-oz',
    _910300400: '910-300-400',
    _910300410: '910-300-410',
    _100x70mm: '100x70mm'
};
/**
 * @export
 */
export const GetOrderIncludeDocsEnum = {
    Include: 'include',
    Url: 'URL'
};
