import { PartnerOrderOrdersV4 } from '@packages/codegen/otto';
import { ExtractDocumentTypeFromTypedRxJsonSchema, RxCollection, RxJsonSchema, toTypedRxJsonSchema } from 'rxdb';

import { literal } from './literal';
import { methods } from './methods';
import { statics } from './statics';

export type Doc = ExtractDocumentTypeFromTypedRxJsonSchema<ReturnType<typeof toTypedRxJsonSchema<typeof literal>>>;
export type Collection = RxCollection<Doc, typeof methods, typeof statics>;

export const schema: RxJsonSchema<Doc> = literal;

export { literal, methods, statics };

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
export type RawOrder = PartnerOrderOrdersV4 | unknown;
