/* tslint:disable */
/* eslint-disable */
/**
 * Parcel DE Shipping API (Post & Parcel Germany)
 * Note: This is the specification of the DPDHL Group Parcel DE Shipping API for Post & Parcel Germany. This REST web service allows business customers to create shipping labels on demand.
 *
 * The version of the OpenAPI document: 2.1.6
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
export const DocumentFileFormatEnum = {
    Zpl2: 'ZPL2',
    Pdf: 'PDF'
};
export const DocumentPrintFormatEnum = {
    A4Pt: 'A4-PT',
    A4: 'A4',
    _910300700: '910-300-700',
    _910300700OZOz: '910-300-700-oZ/oz',
    _910300300: '910-300-300',
    _910300300Oz: '910-300-300-oz',
    _910300710: '910-300-710',
    _910300600610: '910-300-600/610',
    _910300400410: '910-300-400/410',
    _100x70mm: '100x70mm'
};
