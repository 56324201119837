import type { Doc } from './index';

export const methods = {
	// setTrackingNumber(this: Doc, trackingNumber: string) {
	// 	console.log('in addShippingDocuments', this, trackingNumber);
	// },
	// setReturnTrackingNumber(this: Doc, returnTrackingNumber: string) {
	// 	console.log('in addShippingDocuments', this, returnTrackingNumber);
	// },
	// here we should have complex methods like "is shipped" or "is returned" or something
};
