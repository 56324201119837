/* tslint:disable */
/* eslint-disable */
/**
 * Parcel DE Shipping API (Post & Parcel Germany)
 * Note: This is the specification of the DPDHL Group Parcel DE Shipping API for Post & Parcel Germany. This REST web service allows business customers to create shipping labels on demand.
 *
 * The version of the OpenAPI document: 2.1.6
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
export * from './api/general-api';
export * from './api/manifests-api';
export * from './api/shipments-and-labels-api';
