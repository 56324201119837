export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The AWSDate scalar type represents a valid extended ISO 8601 Date string. In other words, this scalar type accepts date strings of the form YYYY-MM-DD. This scalar type can also accept time zone offsets. For example, 1970-01-01Z, 1970-01-01-07:00 and 1970-01-01+05:30 are all valid dates. The time zone offset must either be Z (representing the UTC time zone) or be in the format ±hh:mm:ss. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSDate: { input: any; output: any; }
  /** The AWSDateTime scalar type represents a valid extended ISO 8601 DateTime string. In other words, this scalar type accepts datetime strings of the form YYYY-MM-DDThh:mm:ss.sssZ. The field after the seconds field is a nanoseconds field. It can accept between 1 and 9 digits. The seconds and nanoseconds fields are optional (the seconds field must be specified if the nanoseconds field is to be used). The time zone offset is compulsory for this scalar. The time zone offset must either be Z (representing the UTC time zone) or be in the format ±hh:mm:ss. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSDateTime: { input: any; output: any; }
  /** The AWSEmail scalar type represents an Email address string that complies with RFC 822. For example, username@example.com is a valid Email address. */
  AWSEmail: { input: any; output: any; }
  /** The AWSIPAddress scalar type represents a valid IPv4 or IPv6 address string. */
  AWSIPAddress: { input: any; output: any; }
  /**
   * The AWSJSON scalar type represents a JSON string that complies with RFC 8259.
   *
   * Maps like {\"upvotes\": 10}, lists like [1,2,3], and scalar values like \"AWSJSON example string\", 1, and true are accepted as valid JSON. They will automatically be parsed and loaded in the resolver mapping templates as Maps, Lists, or Scalar values rather than as the literal input strings. Invalid JSON strings like {a: 1}, {'a': 1} and Unquoted string will throw GraphQL validation errors.
   */
  AWSJSON: { input: any; output: any; }
  /** The AWSPhone scalar type represents a valid Phone Number. Phone numbers are serialized and deserialized as Strings. Phone numbers provided may be whitespace delimited or hyphenated. The number can specify a country code at the beginning but this is not required. */
  AWSPhone: { input: any; output: any; }
  /**
   * The AWSTime scalar type represents a valid extended ISO 8601 Time string. In other words, this scalar type accepts time strings of the form hh:mm:ss.sss. The field after the seconds field is a nanoseconds field. It can accept between 1 and 9 digits. The seconds and nanoseconds fields are optional (the seconds field must be specified if the nanoseconds field is to be used). This scalar type can also accept time zone offsets.
   *
   * For example, 12:30Z, 12:30:24-07:00 and 12:30:24.500+05:30 are all valid time strings.
   *
   * The time zone offset must either be Z (representing the UTC time zone) or be in the format hh:mm:ss. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard.
   */
  AWSTime: { input: any; output: any; }
  /** The AWSTimestamp scalar type represents the number of seconds that have elapsed since 1970-01-01T00:00Z. Timestamps are serialized and deserialized as numbers. Negative values are also accepted and these represent the number of seconds till 1970-01-01T00:00Z. */
  AWSTimestamp: { input: any; output: any; }
  /** The AWSURL scalar type represents a valid URL string. The URL may use any scheme and may also be a local URL (Ex: <http://localhost/>). URLs without schemes are considered invalid. URLs which contain double slashes are also considered invalid. */
  AWSURL: { input: any; output: any; }
};

export enum ApiEnvironment {
  Production = 'production',
  Sandbox = 'sandbox'
}

export type App = {
  created_at?: Maybe<Scalars['AWSDateTime']['output']>;
  environment?: Maybe<ApiEnvironment>;
  id: Scalars['ID']['output'];
  status?: Maybe<AppStatus>;
  updated_at?: Maybe<Scalars['AWSDateTime']['output']>;
};

export enum AppStatus {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED'
}

export type BasicCredentials = {
  __typename?: 'BasicCredentials';
  password?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type BasicCredentialsInput = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type ClientAccount = {
  __typename?: 'ClientAccount';
  created_at: Scalars['AWSDateTime']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  type: ClientType;
  updated_at: Scalars['AWSDateTime']['output'];
};

export enum ClientType {
  CloudPrint = 'CloudPrint'
}

export type ConnectClientRequestInput = {
  clientAccountId: Scalars['ID']['input'];
};

export type CreateTenantInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateTenantPayload = {
  __typename?: 'CreateTenantPayload';
  ownerAccountId: Scalars['ID']['output'];
  tenantId: Scalars['ID']['output'];
};

export type Credentials = BasicCredentials | TokenCredentials;

export type DhlApp = App & {
  __typename?: 'DHLApp';
  billingNumbers?: Maybe<DhlBillingNumbers>;
  created_at?: Maybe<Scalars['AWSDateTime']['output']>;
  credentials?: Maybe<DhlCredentials>;
  defaultLabelFormat?: Maybe<DhlLabelFormat>;
  environment?: Maybe<ApiEnvironment>;
  id: Scalars['ID']['output'];
  profile?: Maybe<Scalars['String']['output']>;
  receiverId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<AppStatus>;
  updated_at?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type DhlBillingNumbers = {
  __typename?: 'DHLBillingNumbers';
  V01PAK?: Maybe<Scalars['String']['output']>;
  V01PAK_GREEN?: Maybe<Scalars['String']['output']>;
  V01PAK_VAS?: Maybe<Scalars['String']['output']>;
  V07PAK?: Maybe<Scalars['String']['output']>;
  V07PAK_GREEN?: Maybe<Scalars['String']['output']>;
  V53WPAK?: Maybe<Scalars['String']['output']>;
  V53WPAK_GREEN?: Maybe<Scalars['String']['output']>;
  V54EPAK?: Maybe<Scalars['String']['output']>;
  V54EPAK_GREEN?: Maybe<Scalars['String']['output']>;
  V62WP?: Maybe<Scalars['String']['output']>;
  V62WP_GREEN?: Maybe<Scalars['String']['output']>;
  V66WPI_1?: Maybe<Scalars['String']['output']>;
  V66WPI_2?: Maybe<Scalars['String']['output']>;
  V66WPI_GREEN?: Maybe<Scalars['String']['output']>;
};

export type DhlBillingNumbersInput = {
  V01PAK?: InputMaybe<Scalars['String']['input']>;
  V01PAK_GREEN?: InputMaybe<Scalars['String']['input']>;
  V01PAK_VAS?: InputMaybe<Scalars['String']['input']>;
  V07PAK?: InputMaybe<Scalars['String']['input']>;
  V07PAK_GREEN?: InputMaybe<Scalars['String']['input']>;
  V53WPAK?: InputMaybe<Scalars['String']['input']>;
  V53WPAK_GREEN?: InputMaybe<Scalars['String']['input']>;
  V54EPAK?: InputMaybe<Scalars['String']['input']>;
  V54EPAK_GREEN?: InputMaybe<Scalars['String']['input']>;
  V62WP?: InputMaybe<Scalars['String']['input']>;
  V62WP_GREEN?: InputMaybe<Scalars['String']['input']>;
  V66WPI_1?: InputMaybe<Scalars['String']['input']>;
  V66WPI_2?: InputMaybe<Scalars['String']['input']>;
  V66WPI_GREEN?: InputMaybe<Scalars['String']['input']>;
};

export type DhlCredentials = {
  __typename?: 'DHLCredentials';
  password?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export enum DhlLabelFormat {
  Format_100x70mm = 'FORMAT_100x70mm',
  Format_910300300 = 'FORMAT_910300300',
  Format_910300300Oz = 'FORMAT_910300300Oz',
  Format_910300400 = 'FORMAT_910300400',
  Format_910300410 = 'FORMAT_910300410',
  Format_910300600 = 'FORMAT_910300600',
  Format_910300610 = 'FORMAT_910300610',
  Format_910300700 = 'FORMAT_910300700',
  Format_910300700Oz = 'FORMAT_910300700Oz',
  Format_910300710 = 'FORMAT_910300710',
  FormatA4 = 'FORMAT_A4'
}

export type Market = {
  __typename?: 'Market';
  environment?: Maybe<ApiEnvironment>;
  id: Scalars['ID']['output'];
  merchantId: Scalars['String']['output'];
  provider: MarketProvider;
};

export enum MarketProvider {
  Amazon = 'Amazon',
  Kaufland = 'Kaufland',
  Otto = 'Otto',
  Zalando = 'Zalando'
}

export type Message = {
  __typename?: 'Message';
  created_at: Scalars['AWSDateTime']['output'];
  from: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  payload?: Maybe<Scalars['AWSJSON']['output']>;
  tenant_pk: Scalars['String']['output'];
  to?: Maybe<Scalars['String']['output']>;
  topic: MessageTopic;
};

export type MessageInput = {
  payload?: InputMaybe<Scalars['AWSJSON']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
  topic: MessageTopic;
};

export enum MessageTopic {
  Echo = 'ECHO',
  Hello = 'HELLO',
  LockOrders = 'LOCK_ORDERS',
  Print = 'PRINT',
  PublishPrinters = 'PUBLISH_PRINTERS',
  RequestPrinters = 'REQUEST_PRINTERS',
  UnlockOrders = 'UNLOCK_ORDERS',
  UpdatePrinterStatus = 'UPDATE_PRINTER_STATUS'
}

export type Mutation = {
  __typename?: 'Mutation';
  connectClientRequest: Otp;
  createTenant: CreateTenantPayload;
  publishMessage: Message;
  updateAppStatus: App;
  updateDHLApp?: Maybe<DhlApp>;
  updateUser: User;
  upsertClientAccount: ClientAccount;
};


export type MutationConnectClientRequestArgs = {
  input: ConnectClientRequestInput;
};


export type MutationCreateTenantArgs = {
  input: CreateTenantInput;
};


export type MutationPublishMessageArgs = {
  input: MessageInput;
};


export type MutationUpdateAppStatusArgs = {
  input: UpdateAppStatusInput;
};


export type MutationUpdateDhlAppArgs = {
  input?: InputMaybe<UpdateDhlAppInput>;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpsertClientAccountArgs = {
  input: UpsertClientAccountInput;
};

export type Otp = {
  __typename?: 'OTP';
  code: Scalars['String']['output'];
  expiresAt: Scalars['AWSDateTime']['output'];
  id: Scalars['ID']['output'];
};

export type Order = {
  __typename?: 'Order';
  details: RawOrder;
  externalOrderNumber: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  recipientHash: Scalars['String']['output'];
  skus: Array<Scalars['String']['output']>;
  status: OrderStatus;
};

export enum OrderStatus {
  Cancelled = 'CANCELLED',
  Open = 'OPEN',
  Pending = 'PENDING',
  Queued = 'QUEUED',
  Sent = 'SENT',
  Submitted = 'SUBMITTED'
}

export type OttoAddressOrdersV4 = {
  __typename?: 'OttoAddressOrdersV4';
  addition: Scalars['String']['output'];
  city: Scalars['String']['output'];
  countryCode: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  houseNumber: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  salutation: Scalars['String']['output'];
  street: Scalars['String']['output'];
  title: Scalars['String']['output'];
  zipCode: Scalars['String']['output'];
};

export type OttoAmountOrdersV4 = {
  __typename?: 'OttoAmountOrdersV4';
  amount: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
};

export enum OttoCancellationReason {
  CancelledOnCustomerWish = 'CANCELLED_ON_CUSTOMER_WISH',
  CancelledOnPartnerWish = 'CANCELLED_ON_PARTNER_WISH',
  PaymentAborted = 'PAYMENT_ABORTED'
}

export type OttoDimensionOrdersV4 = {
  __typename?: 'OttoDimensionOrdersV4';
  displayName: Scalars['String']['output'];
  type: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export enum OttoFulfillmentStatus {
  Announced = 'ANNOUNCED',
  CancelledByMarketplace = 'CANCELLED_BY_MARKETPLACE',
  CancelledByPartner = 'CANCELLED_BY_PARTNER',
  Processable = 'PROCESSABLE',
  Returned = 'RETURNED',
  Sent = 'SENT'
}

export type OttoInitialDeliveryFeeOrdersV4 = {
  __typename?: 'OttoInitialDeliveryFeeOrdersV4';
  deliveryFeeAmount?: Maybe<OttoAmountOrdersV4>;
  name: Scalars['String']['output'];
  positionItemIds?: Maybe<Array<Scalars['String']['output']>>;
  vatRate: Scalars['Float']['output'];
};

export type OttoInitialDiscountOrdersV4 = {
  __typename?: 'OttoInitialDiscountOrdersV4';
  discountAmount?: Maybe<OttoAmountOrdersV4>;
  discountId: Scalars['String']['output'];
  discountName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  positionItemIds?: Maybe<Array<Scalars['String']['output']>>;
  vatRate: Scalars['Float']['output'];
};

export type OttoLinkOrdersV4 = {
  __typename?: 'OttoLinkOrdersV4';
  href: Scalars['String']['output'];
  rel: Scalars['String']['output'];
};

export type OttoMarketplaceApp = App & {
  __typename?: 'OttoMarketplaceApp';
  created_at?: Maybe<Scalars['AWSDateTime']['output']>;
  environment?: Maybe<ApiEnvironment>;
  externalId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  status?: Maybe<AppStatus>;
  updated_at?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type OttoOrderLifecycleInformationOrdersV4 = {
  __typename?: 'OttoOrderLifecycleInformationOrdersV4';
  lifecycleChangeDate: Scalars['String']['output'];
};

export type OttoPartnerOrderOrdersV4 = {
  __typename?: 'OttoPartnerOrderOrdersV4';
  deliveryAddress?: Maybe<OttoAddressOrdersV4>;
  initialDeliveryFees?: Maybe<Array<Maybe<OttoInitialDeliveryFeeOrdersV4>>>;
  initialDiscounts?: Maybe<Array<Maybe<OttoInitialDiscountOrdersV4>>>;
  invoiceAddress?: Maybe<OttoAddressOrdersV4>;
  lastModifiedDate: Scalars['String']['output'];
  links?: Maybe<Array<Maybe<OttoLinkOrdersV4>>>;
  orderDate: Scalars['String']['output'];
  orderLifecycleInformation?: Maybe<OttoOrderLifecycleInformationOrdersV4>;
  orderNumber: Scalars['String']['output'];
  payment?: Maybe<OttoPaymentOrdersV4>;
  positionItems?: Maybe<Array<Maybe<OttoPositionItemOrdersV4>>>;
  salesOrderId: Scalars['String']['output'];
};

export type OttoPaymentOrdersV4 = {
  __typename?: 'OttoPaymentOrdersV4';
  paymentMethod: Scalars['String']['output'];
};

export type OttoPositionItemOrdersV4 = {
  __typename?: 'OttoPositionItemOrdersV4';
  cancellationDate: Scalars['String']['output'];
  cancellationReason?: Maybe<OttoCancellationReason>;
  deliveryServiceHes: Scalars['Boolean']['output'];
  expectedDeliveryDate: Scalars['String']['output'];
  fulfillmentStatus?: Maybe<OttoFulfillmentStatus>;
  itemValueDiscount?: Maybe<OttoAmountOrdersV4>;
  itemValueGrossPrice?: Maybe<OttoAmountOrdersV4>;
  itemValueReducedGrossPrice?: Maybe<OttoAmountOrdersV4>;
  positionItemId: Scalars['String']['output'];
  processableDate: Scalars['String']['output'];
  product?: Maybe<OttoProductOrdersV4>;
  returnedDate: Scalars['String']['output'];
  sentDate: Scalars['String']['output'];
  trackingInfo?: Maybe<OttoTrackingInfoOrdersV4>;
  weeePickup: Scalars['Boolean']['output'];
};

export type OttoProductOrdersV4 = {
  __typename?: 'OttoProductOrdersV4';
  articleNumber: Scalars['String']['output'];
  dimensions?: Maybe<Array<Maybe<OttoDimensionOrdersV4>>>;
  ean: Scalars['String']['output'];
  productTitle: Scalars['String']['output'];
  sku: Scalars['String']['output'];
  vatRate: Scalars['Float']['output'];
};

export type OttoRawOrder = RawOrder & {
  __typename?: 'OttoRawOrder';
  deliveryAddress?: Maybe<OttoAddressOrdersV4>;
  id: Scalars['ID']['output'];
  initialDeliveryFees?: Maybe<Array<Maybe<OttoInitialDeliveryFeeOrdersV4>>>;
  initialDiscounts?: Maybe<Array<Maybe<OttoInitialDiscountOrdersV4>>>;
  invoiceAddress?: Maybe<OttoAddressOrdersV4>;
  lastModifiedDate: Scalars['String']['output'];
  links?: Maybe<Array<Maybe<OttoLinkOrdersV4>>>;
  orderDate: Scalars['String']['output'];
  orderId: Scalars['ID']['output'];
  orderLifecycleInformation?: Maybe<OttoOrderLifecycleInformationOrdersV4>;
  orderNumber: Scalars['String']['output'];
  payment?: Maybe<OttoPaymentOrdersV4>;
  positionItems?: Maybe<Array<Maybe<OttoPositionItemOrdersV4>>>;
  salesOrderId: Scalars['String']['output'];
};

export type OttoTrackingInfoOrdersV4 = {
  __typename?: 'OttoTrackingInfoOrdersV4';
  carrier: Scalars['String']['output'];
  carrierServiceCode: Scalars['String']['output'];
  trackingNumber: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  clientAccounts: Array<ClientAccount>;
  installedApps: Array<App>;
  markets: Array<Market>;
  shippers?: Maybe<Shippers>;
  user?: Maybe<User>;
};


export type QueryClientAccountsArgs = {
  type?: InputMaybe<ClientType>;
};


export type QueryUserArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type RawOrder = {
  id: Scalars['ID']['output'];
  orderId: Scalars['ID']['output'];
};

export type Shippers = {
  __typename?: 'Shippers';
  dhl?: Maybe<DhlApp>;
};

export type Subscription = {
  __typename?: 'Subscription';
  onMessage?: Maybe<Message>;
};


export type SubscriptionOnMessageArgs = {
  filter?: InputMaybe<Scalars['AWSJSON']['input']>;
};

export type Tenant = {
  __typename?: 'Tenant';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type TokenCredentials = {
  __typename?: 'TokenCredentials';
  access_token?: Maybe<Scalars['String']['output']>;
};

export type UpdateAppStatusInput = {
  appId: Scalars['ID']['input'];
  status: AppStatus;
};

export type UpdateDhlAppInput = {
  billingNumbers?: InputMaybe<DhlBillingNumbersInput>;
  credentials?: InputMaybe<BasicCredentialsInput>;
  defaultLabelFormat?: InputMaybe<DhlLabelFormat>;
  environment?: InputMaybe<ApiEnvironment>;
  profile?: InputMaybe<Scalars['String']['input']>;
  receiverId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertClientAccountInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type: ClientType;
};

export type User = {
  __typename?: 'User';
  accounts: Array<UserAccount>;
  email?: Maybe<Scalars['String']['output']>;
  firstname?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastname?: Maybe<Scalars['String']['output']>;
};

export type UserAccount = {
  __typename?: 'UserAccount';
  id: Scalars['ID']['output'];
  role: Scalars['String']['output'];
  tenant: Tenant;
};
