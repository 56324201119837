/* tslint:disable */
/* eslint-disable */
/**
 * Parcel DE Shipping API (Post & Parcel Germany)
 * Note: This is the specification of the DPDHL Group Parcel DE Shipping API for Post & Parcel Germany. This REST web service allows business customers to create shipping labels on demand.
 *
 * The version of the OpenAPI document: 2.1.6
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * A valid country code consisting of three characters according to ISO 3166-1 alpha-3.
 * @export
 * @enum {string}
 */
export const Country = {
    Abw: 'ABW',
    Afg: 'AFG',
    Ago: 'AGO',
    Aia: 'AIA',
    Ala: 'ALA',
    Alb: 'ALB',
    And: 'AND',
    Are: 'ARE',
    Arg: 'ARG',
    Arm: 'ARM',
    Asm: 'ASM',
    Atg: 'ATG',
    Aus: 'AUS',
    Aut: 'AUT',
    Aze: 'AZE',
    Bdi: 'BDI',
    Bel: 'BEL',
    Ben: 'BEN',
    Bes: 'BES',
    Bfa: 'BFA',
    Bgd: 'BGD',
    Bgr: 'BGR',
    Bhr: 'BHR',
    Bhs: 'BHS',
    Bih: 'BIH',
    Blm: 'BLM',
    Blr: 'BLR',
    Blz: 'BLZ',
    Bmu: 'BMU',
    Bol: 'BOL',
    Bra: 'BRA',
    Brb: 'BRB',
    Brn: 'BRN',
    Btn: 'BTN',
    Bvt: 'BVT',
    Bwa: 'BWA',
    Caf: 'CAF',
    Can: 'CAN',
    Cck: 'CCK',
    Che: 'CHE',
    Chl: 'CHL',
    Chn: 'CHN',
    Civ: 'CIV',
    Cmr: 'CMR',
    Cod: 'COD',
    Cog: 'COG',
    Cok: 'COK',
    Col: 'COL',
    Com: 'COM',
    Cpv: 'CPV',
    Cri: 'CRI',
    Cub: 'CUB',
    Cuw: 'CUW',
    Cxr: 'CXR',
    Cym: 'CYM',
    Cyp: 'CYP',
    Cze: 'CZE',
    Deu: 'DEU',
    Dji: 'DJI',
    Dma: 'DMA',
    Dnk: 'DNK',
    Dom: 'DOM',
    Dza: 'DZA',
    Ecu: 'ECU',
    Egy: 'EGY',
    Eri: 'ERI',
    Esp: 'ESP',
    Est: 'EST',
    Eth: 'ETH',
    Fin: 'FIN',
    Fji: 'FJI',
    Flk: 'FLK',
    Fra: 'FRA',
    Fro: 'FRO',
    Fsm: 'FSM',
    Gab: 'GAB',
    Gbr: 'GBR',
    Geo: 'GEO',
    Ggy: 'GGY',
    Gha: 'GHA',
    Gib: 'GIB',
    Gin: 'GIN',
    Glp: 'GLP',
    Gmb: 'GMB',
    Gnb: 'GNB',
    Gnq: 'GNQ',
    Grc: 'GRC',
    Grd: 'GRD',
    Grl: 'GRL',
    Gtm: 'GTM',
    Guf: 'GUF',
    Gum: 'GUM',
    Guy: 'GUY',
    Hkg: 'HKG',
    Hmd: 'HMD',
    Hnd: 'HND',
    Hrv: 'HRV',
    Hti: 'HTI',
    Hun: 'HUN',
    Idn: 'IDN',
    Imn: 'IMN',
    Ind: 'IND',
    Irl: 'IRL',
    Irn: 'IRN',
    Irq: 'IRQ',
    Isl: 'ISL',
    Isr: 'ISR',
    Ita: 'ITA',
    Jam: 'JAM',
    Jey: 'JEY',
    Jor: 'JOR',
    Jpn: 'JPN',
    Kaz: 'KAZ',
    Ken: 'KEN',
    Kgz: 'KGZ',
    Khm: 'KHM',
    Kir: 'KIR',
    Kna: 'KNA',
    Kor: 'KOR',
    Kwt: 'KWT',
    Lao: 'LAO',
    Lbn: 'LBN',
    Lbr: 'LBR',
    Lby: 'LBY',
    Lca: 'LCA',
    Lie: 'LIE',
    Lka: 'LKA',
    Lso: 'LSO',
    Ltu: 'LTU',
    Lux: 'LUX',
    Lva: 'LVA',
    Mac: 'MAC',
    Maf: 'MAF',
    Mar: 'MAR',
    Mco: 'MCO',
    Mda: 'MDA',
    Mdg: 'MDG',
    Mdv: 'MDV',
    Mex: 'MEX',
    Mhl: 'MHL',
    Mkd: 'MKD',
    Mli: 'MLI',
    Mlt: 'MLT',
    Mmr: 'MMR',
    Mne: 'MNE',
    Mng: 'MNG',
    Mnp: 'MNP',
    Moz: 'MOZ',
    Mrt: 'MRT',
    Msr: 'MSR',
    Mtq: 'MTQ',
    Mus: 'MUS',
    Mwi: 'MWI',
    Mys: 'MYS',
    Myt: 'MYT',
    Nam: 'NAM',
    Ncl: 'NCL',
    Ner: 'NER',
    Nfk: 'NFK',
    Nga: 'NGA',
    Nic: 'NIC',
    Niu: 'NIU',
    Nld: 'NLD',
    Nor: 'NOR',
    Npl: 'NPL',
    Nru: 'NRU',
    Nzl: 'NZL',
    Omn: 'OMN',
    Pak: 'PAK',
    Pan: 'PAN',
    Pcn: 'PCN',
    Per: 'PER',
    Phl: 'PHL',
    Plw: 'PLW',
    Png: 'PNG',
    Pol: 'POL',
    Pri: 'PRI',
    Prk: 'PRK',
    Prt: 'PRT',
    Pry: 'PRY',
    Pse: 'PSE',
    Pyf: 'PYF',
    Qat: 'QAT',
    Reu: 'REU',
    Rou: 'ROU',
    Rus: 'RUS',
    Rwa: 'RWA',
    Sau: 'SAU',
    Sdn: 'SDN',
    Sen: 'SEN',
    Sgp: 'SGP',
    Shn: 'SHN',
    Sjm: 'SJM',
    Slb: 'SLB',
    Sle: 'SLE',
    Slv: 'SLV',
    Smr: 'SMR',
    Som: 'SOM',
    Spm: 'SPM',
    Srb: 'SRB',
    Ssd: 'SSD',
    Stp: 'STP',
    Sur: 'SUR',
    Svk: 'SVK',
    Svn: 'SVN',
    Swe: 'SWE',
    Swz: 'SWZ',
    Sxm: 'SXM',
    Syc: 'SYC',
    Syr: 'SYR',
    Tca: 'TCA',
    Tcd: 'TCD',
    Tgo: 'TGO',
    Tha: 'THA',
    Tjk: 'TJK',
    Tkl: 'TKL',
    Tkm: 'TKM',
    Tls: 'TLS',
    Ton: 'TON',
    Tto: 'TTO',
    Tun: 'TUN',
    Tur: 'TUR',
    Tuv: 'TUV',
    Twn: 'TWN',
    Tza: 'TZA',
    Uga: 'UGA',
    Ukr: 'UKR',
    Ury: 'URY',
    Usa: 'USA',
    Uzb: 'UZB',
    Vat: 'VAT',
    Vct: 'VCT',
    Ven: 'VEN',
    Vgb: 'VGB',
    Vir: 'VIR',
    Vnm: 'VNM',
    Vut: 'VUT',
    Wlf: 'WLF',
    Wsm: 'WSM',
    Yem: 'YEM',
    Zaf: 'ZAF',
    Zmb: 'ZMB',
    Zwe: 'ZWE',
    Unknown: 'UNKNOWN'
};
