import React, { PropsWithChildren } from 'react';

import { Database, createDatabase } from '@/lib/db/rxdb-database';
import { formatTenantPkToDatabaseNamespace } from '@/lib/format';

import { useUserSessionAccounts } from './UserSessionProvider/user-session-hooks';

declare global {
	interface Window {
		___rxdb: Database | null;
	}
}

const RxdbContext = React.createContext<{ db: null | Database }>({ db: null });

export const RxdbProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const { currentAccount } = useUserSessionAccounts();

	const [db, setDb] = React.useState<Database | null>(null);

	const initRef = React.useRef(false);

	React.useEffect(() => {
		if (initRef.current) return;
		initRef.current = true;

		async function init() {
			if (window.___rxdb) {
				await window.___rxdb.destroy();
			}

			const _db = await createDatabase(formatTenantPkToDatabaseNamespace(currentAccount.pk));
			window.___rxdb = _db;
			setDb(_db);
			initRef.current = false;
		}

		void init();

		return () => {
			void window.___rxdb?.destroy();
			window.___rxdb = null;
		};
	}, [currentAccount.pk]);

	return <RxdbContext.Provider value={{ db }}>{db ? children : null}</RxdbContext.Provider>;
};

export const useRxdb = () => {
	const { db } = React.useContext(RxdbContext);
	return db;
};

export const getRxdb = () => {
	return window.___rxdb;
};
